import { FC, ReactNode } from 'react'

import { Container, Inner } from './Page.styles'

interface PageProps {
  children?: ReactNode
}

export const Page: FC<PageProps> = ({ children }) => {
  return (
    <Container>
      <Inner>{children}</Inner>
    </Container>
  )
}

import { FC } from 'react'

import { Heading, SubHeading, Text, Wrapper } from './SiteCoreContent.styles'

interface SiteCoreContentProps {
  title?: string
  subtitle: string
  intro: string
  text: string
}

export const SiteCoreContent: FC<SiteCoreContentProps> = ({
  title,
  subtitle,
  intro,
  text,
}) => {
  return (
    <Wrapper>
      {title && <Heading>{title}</Heading>}
      {subtitle && <SubHeading>{subtitle}</SubHeading>}
      <Text
        dangerouslySetInnerHTML={{
          __html: `${intro}${text}`,
        }}
      />
    </Wrapper>
  )
}

import { NextPage } from 'next'

import type { AyvensThemePageProp } from '../../types'
import { Logo } from '../Logo'
import { Page } from '../Page'
import { SiteCoreContent } from '../SiteCoreContent'

type ContentPageProps = AyvensThemePageProp & {
  content: {
    title: string
    subtitle: string
    intro: string
    text: string
  }
}

export const ContentPage: NextPage<ContentPageProps> = ({
  content,
  useAyvensTheme,
}) => {
  return (
    <Page>
      <Logo useAyvensTheme={useAyvensTheme} />

      <SiteCoreContent
        title={content.title}
        subtitle={content.subtitle}
        intro={content.intro}
        text={content.text}
      />
    </Page>
  )
}

import type { FC } from 'react'

type SharedValues =
  | 'center'
  | 'end'
  | 'inherit'
  | 'initial'
  | 'left'
  | 'revert'
  | 'right'
  | 'start'
  | 'unset'

type ContentSpecific =
  | 'flex-end'
  | 'flex-start'
  | 'normal'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'

type Direction =
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset'
  | 'column'
  | 'column-reverse'
  | 'row'
  | 'row-reverse'

export interface CenterProps {
  to?: SharedValues | ContentSpecific
  direction?: Direction
  children?: React.ReactNode
}

export const Align: FC<CenterProps> = ({ children, to, direction }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: direction ?? 'column',
        alignContent: !direction?.includes('row') ? to : undefined,
        justifyContent: direction?.includes('row') ? to : undefined,
      }}
    >
      {children}
    </div>
  )
}

import { default as styled } from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 300px;
`

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const SubHeading = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const Text = styled.div`
  a {
    color: var(--vel-color-primary-500);
  }
  p {
    margin-bottom: 24px;
    strong {
      font-size: 20px;
      font-weight: 600;
    }
  }
`

import { StyledAyvensLogo } from './StyledAyvensLogo'
import { StyledLeaseplanLogo } from './StyledLeaseplanLogo'
import { Align } from '../Align'

type Props = { useAyvensTheme?: boolean }
export const Logo = ({ useAyvensTheme }: Props) => {
  return (
    <Align to="center">
      {useAyvensTheme ? (
        <StyledAyvensLogo data-name="Ayvens Logo" size="m" />
      ) : (
        <StyledLeaseplanLogo data-name="LeasePlan Logo" size="s" />
      )}
    </Align>
  )
}

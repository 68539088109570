import { default as styled } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
`

export const Inner = styled.div`
  font-size: 16px;
  position: relative;
  overflow: auto;
  margin: auto;
  background-color: white;
  border-color: 1px solid rgb(99 102 112);
  border-radius: 8px;
  color: rgb(99 102 112);
  font-weight: 500;
  line-height: 1.5;
  width: 100vw;
  height: 100vh;
  padding: 10px 20px;
  text-align: center;

  @media only screen and (min-width: 480px) {
    height: 37.6em;
    max-height: calc(100% + 0.25em);
    width: 94vw;
    max-width: 28em;
  }
`

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const Text = styled.div`
  margin-bottom: 60px;

  a {
    color: var(--vel-color-primary-500);
  }
`
